<template>
    <Fragment>
        <h5 class="mt-12 text-subtitle-1 text-uppercase font-weight-bold" style="color: #000000;">
            <v-icon style="transform: translateY(-2px)">mdi-clipboard-list</v-icon>
            Solicitudes de necesidad
        </h5>
        <v-divider class="mt-1" />
        <p v-if="necesidades.isLoading" class="mb-0 mt-4 text-body font-weight-bold">Cargando necesidades...</p>
        <EmptyComponent 
            v-else
            :empty="!(necesidades.data.length > 0)" 
            colored
            text="Este proceso de compra no posee solicitudes de necesidad."
            class="mt-4"
        >
            <template v-slot:icon>
                <v-icon style="font-size: 48px;" color="var(--v-primary-lighten3) !important">mdi-clipboard-list</v-icon>
            </template>
            <v-expansion-panels>
                <v-expansion-panel
                  v-for="necesidad in necesidades.data"
                  :key="necesidad.id"
                  class="mt-4"
                >
                  <v-expansion-panel-header class="px-4 py-4" style="min-height: 0 !important;">
                    <div class="d-flex flex-column">
                        <b class="text-h6 font-weight-bold">{{ necesidad.correlativo }}</b>
                        <p class="mb-0 text-subtitle-2">{{ necesidad.obs.codigo }} - {{ necesidad.obs.nombre }}</p>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0 py-0">
                    <v-divider />
                    <div class="px-4 py-4">
                        <DataTableComponent
                            :headers="headers"
                            :items="necesidad.financiamientos"
                            :tiene_paginacion="false"
                            no-gutters
                            dense
                        >
                            <template v-slot:item.cifrado_presupuestario="{ item }">
                                <b>{{ item.FinanciamientoUnidad.Fondo.cifrado_presupuestario }}</b>
                            </template>
                            <template v-slot:item.monto="{ item }">
                                {{ toMoneyFormat(item.monto) }}
                            </template>
                        </DataTableComponent>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </EmptyComponent>
    </Fragment>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { EmptyComponent } from '@/components/utils';
import { toMoneyFormat } from "@/utils/data";
import { Fragment } from "vue-fragment";

export default {
    name: 'SolicitudesNecesidad',
    components: { DataTableComponent, Fragment, EmptyComponent },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        necesidades: createLoadable([]),
        headers: [
            { text: 'Código presupuestario', value: 'cifrado_presupuestario', align: 'center', sortable: false },
            { text: 'Monto ($)', value: 'monto', align: 'center', sortable: false },
        ],
    }),
    methods: {
        toMoneyFormat,
        async cargarNecesidades() {
            toggleLoadable(this.necesidades);
            const { data } = await this.services.RefinanciamientoCompras.cargarNecesidades(this.idProcesoCompra);
            setLoadableResponse(this.necesidades, data);
        },
    },
    created() {
        this.cargarNecesidades();
    },
}
</script>