<template>
    <v-container>
        <v-card>
            <v-card-title class="flex-sm-nowrap align-start" style="gap: 8px; line-height: 32px;">
                <NavButton />
                <h3 class="text-h6 font-weight-bold" style="color: var(--v-blueGrayMinsal-darken3); word-break: break-word;">
                    {{ montos.isLoading ? 'Cargando...' : montos.data?.proceso.nombre_proceso }}
                </h3>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card class="px-4 py-4">
                            <p class="mb-0 text-h5 font-weight-bold" style="color: var(--v-info-darken2)">
                                {{ toMoneyFormat(montos.data?.planificado, montos.isLoading) }}
                            </p>
                            <h6 class="text-h6" style="color: #7a7a7c">Monto planificado</h6>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card class="px-4 py-4">
                            <p class="mb-0 text-h5 font-weight-bold" style="color: var(--v-info-darken2)">
                                {{ toMoneyFormat(montos.data?.adjudicado, montos.isLoading) }}
                            </p>
                            <h6 class="text-h6" style="color: #7a7a7c">Monto adjudicado</h6>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card class="px-4 py-4">
                            <p class="mb-0 text-h5 font-weight-bold" style="color: var(--v-info-darken2)">
                                {{ toMoneyFormat(montos.data?.modificado, montos.isLoading) }}
                            </p>
                            <h6 class="text-h6" style="color: #7a7a7c">Monto modificado</h6>
                        </v-card>
                    </v-col>
                </v-row>

                <SolicitudesNecesidad :id-proceso-compra="idProcesoCompra" />
                <RefinanciamientosCompra 
                    :id-proceso-compra="idProcesoCompra"
                    @on-eliminar-refinanciamiento="cargarMontos"
                 />
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import RefinanciamientosCompra from './components/RefinanciamientosCompra.vue';
import SolicitudesNecesidad from "./components/SolicitudesNecesidad.vue";
import NavButton from '@/components/utils/NavButton.vue';
import { toMoneyFormat } from "@/utils/data";

export default {
    components: { NavButton, SolicitudesNecesidad, RefinanciamientosCompra },
    data: () => ({
        idProcesoCompra: null,
        montos: createLoadable(null),
    }),
    methods: {
        toMoneyFormat,
        async cargarMontos() {
            toggleLoadable(this.montos);
            const { data } = await this.services.RefinanciamientoCompras.cargarMontos(this.idProcesoCompra);
            setLoadableResponse(this.montos, data);
        },
    },
    created() {
        this.idProcesoCompra = this.$route.params.id_proceso_compra;
        this.cargarMontos();
    },
}
</script>
<style scoped>
    :deep(.v-expansion-panel-content .v-expansion-panel-content__wrap) {
        padding: 0 !important;
    }
</style>