<template>
    <Fragment>
        <h5 class="mt-12 text-subtitle-1 text-uppercase font-weight-bold" style="color: #000000; line-height: 24px;">
            <v-icon style="transform: translateY(-2px)">mdi-archive-plus</v-icon>
            Refinanciamientos de compra
        </h5>
        <v-divider class="mt-1" />
        <p v-if="refinanciamientos.isLoading" class="mb-0 mt-4 text-body font-weight-bold">Cargando refinanciamientos...</p>
        <EmptyComponent 
            v-else 
            :empty="!(refinanciamientos.data.length > 0)" 
            colored
            text="Este proceso de compra no posee refinanciamientos."
            class="mt-4"
        >   
            <template v-slot:icon>
                <v-icon style="font-size: 48px;" color="var(--v-primary-lighten3) !important">mdi-archive-plus</v-icon>
            </template>
            <template v-slot:extra>
                <v-btn v-if="!esUsuarioUfi" color="primary" class="mt-4" :to="`/refinanciamiento-compras/refinanciar/${idProcesoCompra}`">
                    <v-icon color="white" class="mr-1">mdi-plus</v-icon>
                    Agregar refinanciamiento
                </v-btn>
            </template>
            <v-expansion-panels>
                <v-expansion-panel
                v-for="refinanciamiento in refinanciamientos.data"
                :key="refinanciamiento.id"
                class="mt-4"
                >
                    <v-expansion-panel-header v-slot:default class="px-4">
                        <div class="d-flex justify-space-between align-center flex-wrap pr-2" style="gap: 8px;">
                            <div class="d-flex flex-column">
                                <b class="text-h6 font-weight-bold">{{ refinanciamiento.correlativo }}</b>
                                <p class="mb-0 text-subtitle-2">{{ refinanciamiento.obs.codigo }} - {{ refinanciamiento.obs.nombre }}</p>
                            </div>
                            <div v-if="!esUsuarioUfi" class="d-flex justify-center align-center" style="gap: 4px;">
                                <v-btn icon :to="`/refinanciamiento-compras/refinanciar/ajustar/${idProcesoCompra}/${refinanciamiento.id}`">
                                    <v-icon color="secondary">mdi-wrench</v-icon>
                                </v-btn>
                                <v-btn icon @click.stop="confirmarEliminadoRefinanciamiento(refinanciamiento)">
                                    <v-icon color="secondary">mdi-trash-can</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-0 py-0">
                        <v-divider />
                        <div class="px-4 py-4">
                            <DataTableComponent
                                :headers="headers"
                                :items="refinanciamiento.financiamientos"
                                :tiene_paginacion="false"
                                no-gutters
                                dense
                            >
                                <template v-slot:item.cifrado_presupuestario="{ item }">
                                    <b>{{ item.financiamiento_unidad.fondo.cifrado_presupuestario }}</b>
                                </template>
                                <template v-slot:item.monto_solicitado="{ item }">
                                    {{ toMoneyFormat(item.monto_solicitado) }}
                                </template>
                            </DataTableComponent>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </EmptyComponent>

        <ConfirmationModalComponent 
            :is-open="confirmacionEliminadoAbierta"
            :is-loading="eliminadoRefinanciamiento.isLoading"
            description="¿Desea eliminar el refinanciamiento?"
            @confirm="eliminarRefinanciamiento"
            @cancel="confirmacionEliminadoAbierta = false"
        >
            <template v-slot:body>
                <v-icon color="#404A7A !important" class="mb-4 feedback-modal-icon">mdi mdi-information</v-icon>
                <p class="mb-0">
                    ¿Desea eliminar el refinanciamiento 
                    <b>{{ refinanciamientoActivo?.correlativo }}</b>
                    y todos sus fondos?
                </p>
            </template>
        </ConfirmationModalComponent>
    </Fragment>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent, EmptyComponent } from '@/components/utils';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { toMoneyFormat } from "@/utils/data";
import { Fragment } from "vue-fragment";

export default {
    name: 'RefinanciamientosCompra',
    emits: ["on-eliminar-refinanciamiento"],
    components: { Fragment, DataTableComponent, ConfirmationModalComponent, EmptyComponent, },
    props: {
        idProcesoCompra: { type: [Number, String] },
    },
    data: () => ({
        refinanciamientos: createLoadable([]),
        eliminadoRefinanciamiento: createLoadable(null),
        // UI
        confirmacionEliminadoAbierta: false,
        refinanciamientoActivo: null,
        headers: [
            { text: 'Código presupuestario', value: 'cifrado_presupuestario', align: 'center', sortable: false },
            { text: 'Unidad', value: 'financiamiento_unidad.unidad.nombre', align: 'center', sortable: false },
            { text: 'Monto ($)', value: 'monto_solicitado', align: 'center', sortable: false },
        ],
    }),
    computed: {
        esUsuarioUfi() {
            return this.haveRoles(['ROLE_UFI']);
        },
    },
    methods: {
        toMoneyFormat,
        confirmarEliminadoRefinanciamiento(refinanciamiento) {
            this.confirmacionEliminadoAbierta = true;
            this.refinanciamientoActivo = { ...refinanciamiento };
        },
        // Data
        async cargarRefinanciamientos() {
            toggleLoadable(this.refinanciamientos);
            const { data } = await this.services.RefinanciamientoCompras.listarRefinanciamientosPorObs(this.idProcesoCompra);
            setLoadableResponse(this.refinanciamientos, data);
        },
        async eliminarRefinanciamiento() {
            toggleLoadable(this.eliminadoRefinanciamiento);
            const { data } = await this.services.RefinanciamientoCompras.eliminarRefinanciamiento(this.idProcesoCompra, this.refinanciamientoActivo.id);
            this.confirmacionEliminadoAbierta = false;
            setLoadableResponse(this.eliminadoRefinanciamiento, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.$emit('on-eliminar-refinanciamiento');
                this.cargarRefinanciamientos();
            }

            if (!isResponseSuccesful(data) && data.error.code === 'REFINANCIAMIENTO_INEXISTENTE') this.cargarRefinanciamientos();
        },
    },
    created() {
        this.cargarRefinanciamientos();
    },
}
</script>